import React from "react"
import MiscContent from "../layouts/MiscContent"
import CommingSoonSection from "../layouts/CommingSoonSection"
import BackgroundImageSection from "../layouts/BackgroundImageSection";

export const getPageLayout = layout => {
  const layoutName = layout?.fieldGroupName?.split("_").pop().trim()
  if (!layoutName) return null
  switch (layoutName) {
    case "MiscContent":
      return <MiscContent {...layout} />
    case "CommingSoonSection":
      return <CommingSoonSection {...layout} />
    case "BackgroundImageSection":
      return <BackgroundImageSection {...layout} />
  }
}
